import { memo } from "react"
import { T, cond, prop, compose, always } from "ramda"
import { sizer } from "@ninjaone/utils"
import {
  statusShortHand,
  getStatusBackgroundFromType,
} from "js/includes/configuration/integrations/ticketing/ticketCustomStatus/common"
import {
  getStatusType,
  statusNameMapper,
} from "js/includes/configuration/integrations/ticketing/ticketCustomStatus/common"
import { Box } from "js/includes/components/Styled"

const renderChildren = cond([
  [({ isTile }) => isTile, always(null)],
  [({ shortHand }) => shortHand, compose(statusShortHand, prop("displayName"))],
  [T, prop("displayName")],
])

export const getStatusStyles = status => {
  switch (status) {
    case statusNameMapper.NEW:
    case statusNameMapper.OPEN:
    case statusNameMapper.WAITING:
    case statusNameMapper.PAUSED:
    case statusNameMapper.RESOLVED:
      return { backgroundColor: getStatusBackgroundFromType(status) }
    case statusNameMapper.CLOSED:
      return {
        backgroundColor: getStatusBackgroundFromType(status),
        border: "2px solid",
        borderColor: "ninjaLight",
        color: "ninjaDark",
      }
    default:
      throw new Error(`Invalid status: ${status}`)
  }
}

export default memo(({ status, shortHand = true, isTile = false, ...rest }) => {
  const width = shortHand && !isTile ? sizer(7) : sizer(isTile ? 2 : 30)
  const shortHandPadding = [0, isTile ? 0 : 1]

  const { statusId, displayName } = status
  const statusType = getStatusType(statusId)

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="white"
      fontSize="12px"
      height={sizer(7)}
      width={width}
      minWidth={width}
      lineHeight={sizer(7)}
      padding={shortHand ? shortHandPadding : [0, 2]}
      {...getStatusStyles(statusType)}
      {...rest}
    >
      {renderChildren({ shortHand, isTile, displayName })}
    </Box>
  )
})
